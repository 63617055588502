// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea {
    color: #f17038;

    color: black;
}

.themeColorText {
    color: #f17038;
    color: #f17038;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    color: white
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #FDE995;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLeaveDay {
    background-color: #A6E1C5;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #87CEEB;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightForZeroHours {
    background-color: #CCCCFF;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHalfDayWorkAndLeave {
    background-color: #bbf6f3;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightForHybridDay {
    background-color: #FFC0CB;
}
body .p-datatable .p-datatable-tbody>tr.p-isDeadlineMissed {
    background-color: #e9818169;
}
body .p-datatable .p-datatable-tbody>tr.p-highlightOthersCreated {
    background-color: #dfae98;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-bg-color;
    color: $button-text-color !important;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

body .form-table .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #373534;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 32px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $button-bg-color;
    border-color: $button-hover-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter {
    padding: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 350px;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
        width: 0;
    }

    &:hover {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e7e2e2;
        }
    }
}

.p-column-title {
    text-transform: capitalize !important;
    // color: black;
    // flex: 1;
    // text-align: center;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;

// }

.p-checkbox-box,
.p-checkbox-icon {
    &:hover {
        border-color: darken($color-additional, 10%) !important;
    }
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template !important;

    &:hover {
        background-color: $color-template !important;
    }
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: $color-template !important;
    border-color: $color-template !important;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: 500;
    color: $color-template;
    cursor: pointer;

    >a>img {
        width: 40px;
        height: 20px;
        margin: 5px;
    }
}

// .p-dropdown .p-dropdown-trigger {
//     height: 100%;
// }
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    font-family: unset;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #ffffff;
    padding-left: 10px;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-datatable .p-column-header-content {
    display: block;
    display: flex;
    // align-items: center;
    // justify-content: space-between;
}

.p-datatable .p-column-filter-menu-button {    
    color: #6b7280;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.35rem !important;

    .p-inputtext {
        padding: 0.25rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: rgb(100, 94, 94);
    }

}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: black;
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }
}

p-selectable-row {
    border: 2px solid gray !important;
}

.ck-content {
    min-height: 30vh;
}

// .p-button.p-button-icon-only{
//     height: 3.2rem;
// }

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.p-resizable-column {
    padding: 5px !important;
    // font-weight: 600 !important;
    font-size: 15px !important;
}

.custom-radio-buttons>div {
    margin-bottom: 10px;
}

//css for daily & detail view buttons in the timesheet 
.custom-btn {
    display: flex;
    flex: auto;
    justify-content: center;
    gap: 20px;
}

// .custom-btn button,
// .custom-btn-specific button {
//     height: 35px;
//     border-radius: 20px;
// }

.custom-btn-specific {
    display: flex;
    flex: auto;
    margin-left: 47%;
    gap: 20px;
}

//css for leave balance modal in the employees screen
.leave-modal .modal-content {
    width: 90%;
}

.custom-leave-modal .cardHeader {
    padding: 15px;
}

.custom-leave-modal .form__form-group-label {
    margin: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.custom-leave-modal .form__form-group {
    margin-bottom: 15px;
}

.custom-leave-modal .modal__footer {
    margin-top: 30px;
    margin-bottom: 10px !important;
    justify-content: center !important;
    margin-left: 20rem;
}

//css for settings screen adjustments
.settings-content .p-card-content {
    padding: 0.50rem !important;
}

//css for dropdown values if values has more lenth of text
.p-autocomplete-panel {
    // .p-autocomplete-items {
    //     // width: 280px !important;

    // }

    .p-autocomplete-item {
        white-space: unset;
        padding: 0.50rem 1rem !important;
    }
}

//css for dashboard screen bithday card
.birthDayBackground {
    border-width: 2px;
    border-color: #05f3e7;
    padding: 15px;
    background-color: #ffffff !important;
    width: '50%';
    border-radius: 8px;

    span {
        font-size: 18px;
        font-weight: bold;
    }

    img {
        margin-top: 0px;
        width: 200px;
        height: 100px;
    }
}

.card__title {
    margin-bottom: 20px;
}

.card__title__spacting{
    display: flex;
    align-items: center;
    justify-content: space-between; 
}

// .container__wrap {
//     min-height: 95vh;
// }

table {
    border-collapse: unset !important;
}

/* Custom class to style the scrollbar */
.scrollbar-custom {
    max-height: 69vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #e7e2e2;
    }
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}

.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon{
    color: white;

    &:hover {
        color: #6b7280
    }
}
    
.p-message-icon {
    display: none;
}

.p-message-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    max-width: 100%;
    overflow: auto;
}

.p-message-summary,
.p-message-detail {
    grid-column: 2;
}

.p-message-close {
    grid-column: 1;
    top: -45px;
}

//css for buttons in the login component 
.scale {
    transform: scale(0.80);

    button {
        white-space: nowrap;
    }
}

.text-info {
    color: rgb(47, 39, 131) !important;
}

.login-container .login4 .card .card-body img {
    width: 250px;
    height: 250px;
}

.p-dialog-header {
    background-color: $table-header-bg-color !important;
    color: $table-header-text-color !important;
    height: 4rem !important;
    text-align: left;
    padding: 0.5rem;
}

.p-dialog .p-dialog-header {
    padding: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: $table-header-text-color !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none;
    border-color: transparent;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem .5rem 1rem;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    height: 3.2rem;
}

.baclog-task-selection .p-autocomplete-dropdown {
    height: auto;
}

.p-calendar-w-btn-right .p-datepicker-trigger { 
    height: 3.2rem;
}

.form-field-row .p-calendar-w-btn-right .p-datepicker-trigger { 
    height: auto;
}

.ResizePanel-module_ResizeContentHorizontal__1gGbA {
    max-width: 100%;
}

// .p-button.p-fileupload-choose {
//     padding: 0.75rem;
// }

//css for auto complete field dropdown panel if options having long text
.p-autocomplete-panel .p-autocomplete-item {
    white-space: normal;
    word-wrap: break-word;
    padding: 0.50rem 1rem !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: $font-type;
    display: flex;
    align-items: center;
    height: 100%;
}

.textElipses>a>img {
    width: 40px;
    height: 20px;
    margin: 5px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    text-transform: capitalize;
    font-size: 1.15rem;
}

.inline-multi-select .p-multiselect .p-multiselect-label {
    color: rgb(64, 56, 56);
}

.active-nav-class{
    background-color: #f17038 !important;
    color: #ffffff !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
    background: #ffefe8;
}

.notes-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.notes-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.date-time small {
    color: #777;
    font-size: 10px;
    margin-top: 0.5rem;
    white-space: nowrap;
}

.no-notes p {
    font-size: 1rem;
    color: #666;
    text-align: center;
    padding: 10px;
}

.show-employees{
    font-size: 12px !important;
}

.card-ti{
    font-size: smaller;
}

.nav-disabled {
    pointer-events: none;
    color: gray;
    opacity: 0.6;
}

/* For screens larger than 1500px, hide the small header */
@media screen and (min-width: 1500px) {
    .screen-header-small {
        display: none;
    }
}

/* For screens smaller than 1500px, hide the large header */
@media screen and (max-width: 1500px) {
    .screen-header-large {
        display: none;
    }
}

/* Default for larger screens */
.custom-button {
    font-size: 12px;
    height: 35px;
    padding: 8px 16px;
}
.custom-button-small {
    // padding: 12px 20px !important;
    font-size: 12px;
}
    

/* For screens larger than 1700px, hide the small header */
// @media screen and (min-width: 1600px) {
//     .screen-header-small {
//         display: none;
//     }
// }

// /* For screens smaller than 1700px, hide the large header */
// @media screen and (max-width: 1600px) {
//     .screen-header-large {
//         display: none;
//     }
// }

//css for action buttons and pagination for screen headers
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    min-width: 1rem;
    // width: 2.3rem;
    height: 1rem;
    margin: 0.2rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 1.5rem;
    height: 1.5rem;
    margin: 0.12rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-paginator .p-dropdown {
    height: 2.5rem;
}

.p-button.p-button-sm {
    // padding: 0.30rem 0.95rem !important;
    // height: 2.5rem;
    padding: 0.25rem 0.75rem; /* Reduced padding for a smaller button */
    height: 2rem; /* Adjusted height */
    font-size: 0.875rem; /* Smaller font size for the button text */
    border-radius: 0.3rem;
}

.custom-Button {
    .p-button.p-button-icon-only {
        height: 2.75rem;
    }
}

//css changes for table headers
.p-column-filter-menu {
    .pi-filter-icon {
        visibility: hidden;
        opacity: 0;
    }
}

.p-sortable-column:hover {
    .pi-filter-icon {
        color: #343a40;
    }
}

.p-sortable-column:hover,
.p-resizable-column:hover,
.p-column-filter-menu-button-open,
.p-column-filter-menu-button-active {
    .pi-filter-icon {
        visibility: visible;
        opacity: 1;
    }
}

.p-sortable-column .p-sortable-column-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

.p-sortable-column:hover .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
}

.p-sortable-column.p-highlight .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
    transition: none;
}


.custom-Button {
    .p-button.p-button-icon-only {
        height: 2.75rem;
    }
}

.height-button{
    height: 2rem !important;
}

.p-button.p-button-icon-only {
    width: 2.5rem;
}

.p-inputgroup-addon{
    min-width: 1rem;
}

.p-dropdown-label {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
}

.p-datatable-wrapper{
    height: auto;
    overflow-y: auto;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &:hover {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,.2);
        }
    }

}

.header-button-width{
    width: 55%;
}

.global-search-width{
    width: 15%;
}

.paginator-width{
    width: 30%;
}

.react-resizable-handle-se {
    cursor: row-resize !important;
}

.react-resizable-handle {
    position: absolute;
    width: 100% !important;
    height: 10px !important;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: none !important;
    background-position: bottom right;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-resizable-handle:before {
  content: '·······';
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

// Initially to hide the scrollbar
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
  }


.custom-file-upload-btn{
    width: 2.2rem !important;
    height: 2rem !important;
}

.p-fileupload-buttonbar{
    justify-content: center;
}

.p-fileupload .p-fileupload-buttonbar{
    gap: 0rem;
    padding: 1rem;
    border: none;
    background: transparent;
}

.p-fileupload .p-fileupload-content{
    border: none;
}

.custom-choose-btn {
    color: #ffffff !important; /* White for text */
    background-color: #007bff !important; /* Vibrant blue */
    border: 1px solid #0056b3; /* Subtle darker blue for border */
}

.custom-upload-btn {
    color: #ffffff !important; /* White for text */
    background-color: #28a745 !important; /* Fresh green */
    border: 1px solid #1e7e34; /* Darker green for border */
}

.custom-cancel-btn {
    color: #ffffff !important; /* White for text */
    background-color: #dc3545 !important; /* Bright red */
    border: 1px solid #a71d2a; /* Subtle darker red for border */
}

.inline-elements {
    display: flex;
    align-items: center;
    gap: 8px;
}

// for the toggle button in employees starts
.toggler {
    width: 72px;
  }
  
  .toggler input {
    display: none;
  }
  
  .toggler label {
    display: block;
    position: relative;
    width: 50px;
    height: 25px;
    border: 1px solid #d6d6d6;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;
  }
  
  .toggler label::after {
    display: block;
    border-radius: 100%;
    background-color: #d7062a;
    content: '';
    animation-name: toggler-size;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-direction: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
  }
  
  .toggler label::after, .toggler label .toggler-on, .toggler label .toggler-off {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 18px;
    height: 18px;
    transform: translateY(-50%) translateX(-50%);
    transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }
  
  .toggler input:checked + label::after, .toggler input:checked + label .toggler-on, .toggler input:checked + label .toggler-off {
    left: 75%;
  }
  
  .toggler input:checked + label::after {
    background-color: #50ac5d;
    animation-name: toggler-size2;
  }
  
  .toggler .toggler-on, .toggler .toggler-off {
    opacity: 1;
    z-index: 2;
  }
  
  .toggler input:checked + label .toggler-off, .toggler input:not(:checked) + label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
  }
  
  .toggler .path {
    fill: none;
    stroke: #fefefe;
    stroke-width: 7px;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  
  @keyframes toggler-size {
    0%, 100% {
      width: 18px;
      height: 18px;
    }
  
    50% {
      width: 16px;
      height: 16px;
    }
  }
  
  @keyframes toggler-size2 {
    0%, 100% {
      width: 18px;
      height: 18px;
    }
  
    50% {
      width: 16px;
      height: 16px;
    }
  }
// for the toggle button in employees ends  

.color-indicators {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    align-items: center;
    margin-top: 3px;
}

.indicator {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 11px;
    white-space: nowrap;
}

.indicator-box {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    display: inline-block;
    flex-shrink: 0;
}

.indicators-spacing {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.holiday { background-color: #FDE995; }
.pto { background-color: #A6E1C5; }
.working-less-6 { background-color: #87CEEB; }
.others-created { background-color: #dfae98; }
.working-0 { background-color: #CCCCFF; }
.halfDayWorkAndLeave { background-color: #bbf6f3; }
.hybridDay { background-color: #FFC0CB; }
.timesheetLessThanNine { background-color: #ccdeff; }
.employeeOnLeave { background-color: #fffccc; }
.meetingNotesPass10Days { background-color: #CCCCFF; }
.closeEndDate { background-color: #d4edda; }
.passedEndDate { background-color: #f8d7da; }
.isDeadlineMissed { background-color: #e9818169; }

// notification bell in navbar starts
.notification-box {
    position: relative;
    right: auto;
    z-index: 99;
    top: 5px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.notification-details {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 100;
}

.notification-box:hover .notification-details {
    opacity: 1;
    visibility: visible;
}

.notification-list {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-type {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
}

.notification-names {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
}

.notification-bell {
    animation: bell 1s 1s both infinite;
}

.notification-bell * {
    display: block;
    margin: 0 auto;
    background-color: #f17038;
    box-shadow: 0px 0px 15px #f17038;
}

.bell-top {
    width: 6px;
    height: 6px;
    border-radius: 3px 3px 0 0;
    background-color: orange;
}

.bell-middle {
    width: 25px;
    height: 25px;
    margin-top: -1px;
    border-radius: 12.5px 12.5px 0 0;
    background-color: orange;
}

.bell-bottom {
    position: relative;
    z-index: 0;
    width: 32px;
    height: 2px;
    background-color: orange;
}

.bell-bottom::before,
.bell-bottom::after {
    content: '';
    position: absolute;
    top: -4px;
    border-bottom: 4px solid orange;
}

.bell-bottom::before {
    left: 1px;
    border-bottom: 4px solid orange;
    border-right: 0 solid transparent;
    border-left: 4px solid transparent;
}

.bell-bottom::after {
    right: 1px;
    border-bottom: 4px solid orange;
    border-right: 4px solid transparent;
    border-left: 0 solid transparent;
}

.bell-rad {
    width: 8px;
    height: 4px;
    margin-top: 2px;
    border-radius: 0 0 4px 4px;
    animation: rad 1s 2s both infinite;
    background-color: orange;
}

.notification-count {
    position: absolute;
    z-index: 1;
    top: -6px;
    right: -6px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    border-radius: 50%;
    background-color: #ff4927;
    color: #fff;
    animation: zoom 3s 3s both infinite;
}

@keyframes bell {
    0% { transform: rotate(0); }
    10% { transform: rotate(30deg); }
    20% { transform: rotate(0); }
    80% { transform: rotate(0); }
    90% { transform: rotate(-30deg); }
    100% { transform: rotate(0); }
  }
@keyframes rad {
    0% { transform: translateX(0); }
    10% { transform: translateX(6px); }
    20% { transform: translateX(0); }
    80% { transform: translateX(0); }
    90% { transform: translateX(-6px); }
    100% { transform: translateX(0); }
  }
@keyframes zoom {
    0% { opacity: 0; transform: scale(0); }
    10% { opacity: 1; transform: scale(1); }
    50% { opacity: 1; }
    51% { opacity: 0; }
    100% { opacity: 0; }
  }
@keyframes moon-moving {
    0% { transform: translate(-200%, 600%); }
    100% { transform: translate(800%, -200%); }
}
// notification bell in navbar ends

.credentials-label{
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #f17038 !important;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.grid-view-row .p-badge{
    display: inline !important;
    font-size: 11px;
    font-weight: 700;
    height: 1.45rem;
    line-height: 1.5rem;
    padding: .2rem 0.5rem;
    border-radius: 10px;
}